import { useEffect } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useResetRecoilState, useSetRecoilState } from "recoil";

import {
  APP_BUILD_INFO,
  IS_UNDER_PRODUCTION,
} from "@sellernote/_shared/src/constants";
import { USER_ATOMS } from "@sellernote/_shared/src/states/common/user";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import TextButton from "@sellernote/_sds-v2/src/components/button/TextButton";
import ChevronRightIcon from "@sellernote/_sds-v2/src/components/svgIcons/FourChevronRightIcon";
import withRequireAuth from "@sellernote/shipda-kr/src/hocs/withRequireAuth";
import { MY_PAGE_ATOMS } from "@sellernote/shipda-kr/src/states/forwarding/myPage";

import User from "../User";
import HamburgerMenu from "./HamburgerMenu";
import ShipdaLogo from "./ShipdaLogo";
import Styled from "./index.styles";

function MyPageHeader() {
  const { t } = useTranslation(["common-new"]);

  const router = useRouter();

  const setRoutingTo = useSetRecoilState(MY_PAGE_ATOMS.ROUTING_TO);

  const setLoggedIn = useSetRecoilState(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const resetUserTeamAuthorityInfo = useResetRecoilState(
    USER_ATOMS.USER_TEAM_AUTHORITY_INFO
  );

  useEffect(() => {
    const accessToken =
      window.localStorage.getItem("accessToken") ||
      window.sessionStorage.getItem("accessToken");

    if (accessToken) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
      resetUserTeamAuthorityInfo();
    }
  }, [setLoggedIn, resetUserTeamAuthorityInfo]);

  return (
    <Styled.container>
      {!IS_UNDER_PRODUCTION && APP_BUILD_INFO && (
        <div className="build-info">
          {toFormattedDate(APP_BUILD_INFO.builtAt, "YYYY.MM.DD HH:mm:ss Z")}
        </div>
      )}

      <HamburgerMenu />

      <ShipdaLogo />

      <Styled.heading>My Page</Styled.heading>

      <TextButton
        label={t("common-new:마이페이지_헤더_홈페이지이동")}
        fontSize={14}
        theme="white"
        handleClick={() => {
          setRoutingTo("/");
          router.push("/");
        }}
        iconInfo={{
          Icon: ChevronRightIcon,
          position: "right",
        }}
        className="main-page-button"
      />

      <User isMyPage={true} />
    </Styled.container>
  );
}

export default withRequireAuth(MyPageHeader);
